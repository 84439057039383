@tailwind base;
@tailwind components;

.theme-light {
    /* --color-primary: #1E1E1F; */
    --color-primary: #1E1E1F;
    --color-secondary: #292A2B;
    --color-color1: #EE4B49;
    --color-color2: #FFFFFF;
    --color-color3: #120707;
    --color-color4: #E5C51A;
    --color-color5: #8C9093;
    --color-color6: #02225B;
    --color-color7: #448FF2;
    --color-color8: #D9D0D4;
    --color-gray-light: #4e4e4e;
}

.theme-dark {
    --color-primary: #1d1d1d;
    --color-secondary: #423f3a;
    --color-color1: #EE4B49;
    --color-color2: #FFFFFF;
    --color-color3: #120707;
    --color-color4: #E5C51A;
    --color-color5: #8C9093;
    --color-color6: #02225B;
    --color-color7: #448FF2;
    --color-color8: #D9D0D4;
    --color-gray-light: #4e4e4e;
}

@tailwind utilities;