body,
html {
    background-color: #1E1E1F;
    /* -webkit-user-select: none; */
    /* Safari */
    /* -ms-user-select: none; */
    /* IE 10 and IE 11 */
    /* user-select: none; */
    /* Standard syntax */
    /* font-family: Pacifico, cursive; */

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Scrollbar Style */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #8C9093;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #292A2B;
    /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #292A2B;
    /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #E5C51A;
}

::-webkit-scrollbar-corner {
    background-color: #8C9093;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.textarea-ico {
    -webkit-box-shadow: none;
    box-shadow: none;
    resize: none;
}

.animate-logo {
    animation: rotateLogo 4s linear infinite;
    transform: rotate(360deg)
}

.animate-ping {
    animation: ping 3s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.fade-in {
    animation: fadeIn 1s;
    transition: opacity 1s ease-in-out;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

/* Loader Container */
.loader-container {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    background-color: #FFF;
    background: radial-gradient(ellipse at center, #FF3D00 0%, #FF3D00 14%, #FFF 15%, #FFF 100%);
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}

.loader-container::after,
.loader-container::before {
    content: '';
    position: absolute;
    height: 16px;
    width: 4px;
    background: #FF3D00;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
    transform-origin: 25% 0;
    box-sizing: border-box;
    animation: rotationC 10s linear infinite;
}

.loader-container::before {
    height: 22px;
    width: 2px;
    transform: translateX(-50%) rotate(0deg);
    animation-duration: 1s;
}

@keyframes rotationC {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

/* End Loader Container */

@keyframes wheelSk {

    0%,
    50%,
    100% {
        transform: translatey(0)
    }

    30%,
    90% {
        transform: translatey(-3px)
    }
}

@keyframes lineDropping {
    0% {
        background-position: 100px 11px, 115px 35px, 105px 60px;
        opacity: 1;
    }

    50% {
        background-position: 0px 11px, 20px 35px, 5px 60px
    }

    60% {
        background-position: -30px 11px, 0px 35px, -10px 60px
    }

    75%,
    100% {
        background-position: -30px 11px, -30px 35px, -30px 60px;
        opacity: 0;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes ping {

    75%,
    100% {
        transform: scale(1.1);
        opacity: 0;
    }
}